import React from "react"

import BackgroundImage from "gatsby-background-image"

import IsModernBrowser from "../../../components/_utils/is_browser_modern"
import PrimaryCta, { themes } from "../../../components/buttons/primary_cta"
import { CtaBannerProps } from "../types"

const CtaBanner = ({
  image,
  isFooterInstance,
  ctaLabel,
  ctaPageUrl,
  ctaLink,
  title,
}: CtaBannerProps) => {
  const handleCtaBannerLink = () => {
    if (ctaLink === null && ctaPageUrl !== null) {
      return ctaPageUrl
    } else if (ctaLink !== null && ctaPageUrl === null) {
      return ctaLink
    } else {
      return "/"
    }
  }

  return (
    <>
      <section
        className={`cta-banner-wrapper util__margin-bottom--0 ${
          isFooterInstance ? "is-footer" : ""
        }`}
      >
        <div className="cta-banner-content">
          <div className="cta-banner-text">
            <div className="util__text-align--center util__margin-auto--0">
              <h1 className="typography__display--7 typography__color--white  typography__text-trans--none">
                {title}
              </h1>
              {ctaLabel !== "" && (
                <PrimaryCta
                  theme={themes.white}
                  label={ctaLabel}
                  to={handleCtaBannerLink()}
                />
              )}
            </div>
          </div>
        </div>

        <div className="cta-banner-background-wrapper">
          <img
            loading="lazy"
            src={image.url}
            className="cta-banner"
            alt={image.alt}
          />
          <div className="cta-banner-background" />
        </div>
      </section>
    </>
  )
}

export default CtaBanner
