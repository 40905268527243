import React from "react"

import Img from "gatsby-image"

import heroImageStyles from "../../../assets/styles/modules/banners/hero.module.scss"
import IsModernBrowser from "../../../components/_utils/is_browser_modern"
import { Tldr } from "../../svgs"
import { HeroIntroBannerProps } from "../types"

const { defaultWidth, heroImage } = heroImageStyles
const HeroIntroBanner = ({
  altText,
  children,
  className,
  date,
  hasBreadcrumbs,
  isSubmissionConfirmInstance,
  image,
  hasImage,
  isStoryListing,
  sectionName,
  shortDescription,
  themeColor,
  title,
}: HeroIntroBannerProps) => {
  return (
    <section
      className={`heroBanner ${themeColor === "green" &&
        "green"} ${themeColor === "blue" && "blue"} ${themeColor === "navy" &&
        "navy"} ${themeColor === "grey" && "grey"} ${hasImage &&
        "heroBanner--with-image"} ${className} ${
        isSubmissionConfirmInstance ? "grey__contact-header" : ""
      }${hasBreadcrumbs ? "with-breadcrumbs" : ""}`}
    >
      <div className="heroBanner__content--row">
        {sectionName && (
          <small
            className={`util__zIndex--1 typography__display--exception heroBanner__sectionName`}
          >
            {sectionName}
          </small>
        )}

        <Tldr />

        {title && (
          <h1 className="util__zIndex--1 typography__display--7  heroBanner__title">
            {title}
          </h1>
        )}

        {!isStoryListing && typeof date !== "undefined" && (
          <small className="util__zIndex--1 typography__display--exception">
            {date.substring(0, 10)}
          </small>
        )}
        {shortDescription && (
          <p
            className={`util__zIndex--1 typography__display--3  heroBanner__shortDescription  ${themeColor ===
              "blue" &&
              "typography__color--white"} typography__text-trans--none`}
          >
            {shortDescription}
          </p>
        )}

        {children && (
          <div className="util__zIndex--1 heroBanner__children">{children}</div>
        )}
      </div>

      {hasImage && (
        <div className="heroBanner__content--row">
          <img
            loading="lazy"
            src={image.url}
            alt={altText}
            className={`${heroImage} ${defaultWidth}`}
          />
        </div>
      )}
      <div className="layered-hero-image-bg" />
    </section>
  )
}

export default HeroIntroBanner
