import React, { useState } from "react"
import { Link } from "gatsby"

export const themes = {
  navy: "navy_theme",
  dark: "dark_theme",
  light: "light_theme",
  bright: "bright_theme",
  white: "white_theme",
}

interface PrimaryCtaProps {
  label: string
  theme: string
  to: string
}

const PrimaryCta = (props: PrimaryCtaProps) => {
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseOver = () => {
    setIsHovered(true)
  }

  const handleMouseOut = () => {
    setIsHovered(false)
  }

  const { to, label } = props

  const [theme, setTheme] = useState(props.theme)

  return (
    <Link
      className={`primary-cta`}
      to={to}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <span
        className={`primary-cta__icon-wrapper  primary-cta__icon-wrapper--left  ${theme} ${
          isHovered ? "expand" : ""
        }`}
      >
        <span className={`primary-cta__icon ${isHovered ? "shift" : ""}`} />
      </span>

      <span
        className={`primary-cta__label ${theme}   primary-cta__label--push-right  typography__ff--flamaBold  ${
          isHovered ? "invert" : ""
        }`}
      >
        {label}
      </span>
    </Link>
  )
}

export default PrimaryCta
