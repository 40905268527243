import React from "react"

import { graphql } from "gatsby"

import Head from "../components/global/head"
import CtaBanner from "../components/modules/cta_banner"
import HeroIntroBanner from "../components/modules/hero_intro_banner"

const Four0Four = ({ data }) => {
  const { ctaBannerModule } = data.allDatoCmsHome.edges[0].node

  return (
    <>
      <Head
        title="404 Page Not Found"
        description=""
        keywords=""
        url="https://www.choosetap.com.au/"
        bodyClassname="body__404"
      />

      <section>
        <HeroIntroBanner
          title={`Error 404`}
          sectionName={`Contact us`}
          themeColor="navy"
          shortDescription="Oops! This page – like a good reason for buying single-use plastic bottled water  – doesn’t exist."
        />
        <main>
          {ctaBannerModule.length > 0 && (
            <CtaBanner
              isFooterInstance={true}
              title={ctaBannerModule[0].title}
              ctaLabel={ctaBannerModule[0].ctaLabel}
              ctaLink={ctaBannerModule[0].ctaLink}
              ctaPageUrl={ctaBannerModule[0].ctaPageUrl}
              image={ctaBannerModule[0].backgroundImage}
            />
          )}
        </main>
      </section>
    </>
  )
}

export const query = graphql`
  query {
    allDatoCmsHome {
      edges {
        node {
          ctaBannerModule {
            __typename
            title
            backgroundImage {
              alt
              url
            }
            ctaLink {
              __typename
              ... on DatoCmsPost {
                slug
              }
              __typename
              ... on DatoCmsProgram {
                slug
              }
            }
            ctaLabel
            ctaPageUrl
          }
        }
      }
    }
  }
`

export default Four0Four
